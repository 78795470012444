<template lang="pug">
NuxtLink(:to="localePath")
  slot
</template>

<script lang="ts" setup>
const { locale, defaultLocale, locales } = useI18n()
const props = defineProps<{
  to: string
}>()

const localePath = computed(() => useLocalizePath(props.to, locale.value, defaultLocale, locales.value))
</script>
